import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyCpFSuKNOU16m1jiVK41mNGnCsv_nXluas",

    authDomain: "zh-notificaciones.firebaseapp.com",
  
    projectId: "zh-notificaciones",
  
    storageBucket: "zh-notificaciones.appspot.com",
  
    messagingSenderId: "478870392887",
  
    appId: "1:478870392887:web:140ad7aae1a3b69a85afb2",
  
    measurementId: "G-HVTQ5S1BWM"
  
  
  };
  
  

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/public/firebase-messaging-sw.js')
        .then(registration => {
            console.log('Service worker registered');
            messaging.useServiceWorker(registration);
        })
        .catch(error => {
            console.error('Error registering service worker: ', error);
        });
}