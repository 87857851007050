import React from "react";
import Img from "../assets/LogoZuppaHermanostrazo.png";
import { useEffect, useState } from "react";
import { getToken, onMessage, deleteToken, unsubscribeFromTopic } from "firebase/messaging";
import { messaging } from "../firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";

const SEND_TOKEN_NOTI =
  "https://www.zuppahermanos.com.ar/virtual/api/usuarios.php";
const REFRESH_TOKEN =
  "https://www.zuppahermanos.com.ar/virtual/api/refreshToken.php";

const NUM_ELEMENTOS_POR_PAGINA = 10;

const Inicio = () => {
  const [isReadyForInstall, setIsReadyForInstall] = useState(false);
  const [isReadyForNotification, setIsReadyForNotification] = useState(true);

  const [datosUser, setDatosUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const refreshToken = async (e) => {
    let tokeen = localStorage.getItem("token");
    try {
      await axios
        .post(
          REFRESH_TOKEN,
          JSON.stringify({
            token: tokeen,
            sin_vencimiento: 1,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === "true") {
            let tooken = res.data.token;

            localStorage.setItem("token", tooken);
            window.location.reload();
          } else if (res.data.status === "false") {
            Logout();
          }
        });
    } catch (error) {}
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      //event.preventDefault();
      console.log("👍", "beforeinstallprompt", event);
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);
  async function downloadApp() {
    console.log("👍", "butInstall-clicked");
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      console.log("oops, no prompt event guardado en window");
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    console.log("👍", "userChoice", result);
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }
  const activarMensajes = async () => {
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BL1gYeH0G8quBhVCDtMDwKBaOVQKdCVrFtbSUQhTY_38FD2KCbyitaUH-v0hQn6IQPW3gtVHKnTLLnHUHtOHIEs",
      });

      if (token) {
        localStorage.setItem("tokenNoti", token);
      } else {
        console.log("no tienes token");
      }
    } catch (error) {
      console.log("Error al generar el token:", error);
    }
  };

  const notifyMe = () => {
    Swal.fire({
      width: "20em",
      color: "#fff",
      background: "#2B2B2B",
      title: "¿Activar Notificaciones?",
      showDenyButton: true,
      denyButtonColor: "#666666",
      confirmButtonText: "Activar",
      confirmButtonColor: "#E85217",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (Notification.permission === "granted") {
          console.log("noti activa");
        } else if (
          Notification.permission !== "denied" ||
          Notification.permission === "default"
        ) {
          Notification.requestPermission()
            .then(function (permission) {
              console.log(permission);
              if (permission === "granted") {
                activarMensajes();
                Swal.fire({
                  width: "20em",
                  color: "#fff",
                  background: "#2B2B2B",
                  position: "center",
                  icon: "success",
                  title: "Notificaciones Activadas",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 1600);
              }
            })
            .catch(function (error) {
              console.error(
                "Error al pedir permiso para las notificaciones: ",
                error
              );
              Swal.fire({
                width: "20em",
                color: "#fff",
                background: "#2B2B2B",
                position: "center",
                icon: "error",
                title: "Error al pedir permiso para las notificaciones",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    });
  };

  const Logout = () => {
    localStorage.removeItem("userDataAcopio");
    localStorage.removeItem("token");
    localStorage.removeItem("nombre");
    localStorage.removeItem("apellido");
    localStorage.removeItem("nom_ape");
    window.location.href = "/";
  };
  const mensajeDiario = () => {
    let fechaActual = new Date();
    let _diaActual = fechaActual.toLocaleDateString();
    if (!("Notification" in window)) {
      console.log("no se pueden activar");
    } else if ("Notification" in window) {
      if (localStorage.getItem("tokenNoti") === null) {
        if (localStorage.getItem("fechaNoti") === null) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                  console.log("noti activa");
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        } else if (localStorage.getItem("fechaNoti") < _diaActual) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                  console.log("noti activa");
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        }
      } else if (localStorage.getItem("tokenNoti") !== null) {
        console.log("notificaciones activas");
      }
    }
  };
  const mandarToken = async (e) => {
    let tokenCli = localStorage.getItem("token");
    let tokenNot = localStorage.getItem("tokenNoti");
    let dni = localStorage.getItem("userDataAcopio");
    try {
      await axios
        .post(
          SEND_TOKEN_NOTI,
          JSON.stringify({
            accion: "actualizaTokenFireBase",
            token: tokenCli,
            dni: dni,
            token_firebase_notif: tokenNot,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            //console.log(res)
            if (res.data.id_message === "900") {
              refreshToken();
            }
          }
        });
    } catch (err) {}
  };

  useEffect(() => {
    const handleTokenRefresh = async () => {
      const currentToken = await getToken(messaging);
      console.log("el currentToken es:", currentToken);
      if (
        currentToken &&
        currentToken !== "null" &&
        currentToken !== "undefined"
      ) {
        localStorage.setItem("lastTokenUpdate", String(Date.now()));
        try {
          // Si es la primera vez o ha pasado un mes desde la última actualización
          const lastUpdate = localStorage.getItem("lastTokenUpdate");
          const isFirstTime = !lastUpdate;
          const isOneMonthPassed =
            isFirstTime ||
            Date.now() - Number(lastUpdate) > 30 * 24 * 60 * 60 * 1000;

          if (isFirstTime || isOneMonthPassed) {
            // Eliminar el token actual
            if (currentToken) {
              await deleteToken(messaging);
            }

            // Obtener un nuevo token
            const newToken = await getToken(messaging);
            if (newToken) {
              console.log("Nuevo token:", newToken);
              localStorage.setItem("tokenNoti", newToken);
              mandarToken();
            } else {
              console.log("No se pudo obtener el nuevo token.");
            }
          }
        } catch (error) {
          console.error("Error al eliminar el token:", error);
          console.log("Código de error:", error.code);
          console.log("Mensaje de error:", error.message);
        }
      }
    };

    if (
      localStorage.getItem("lastTokenUpdate") !== null ||
      Date.now() - Number(localStorage.getItem("lastTokenUpdate")) >
        30 * 24 * 60 * 60 * 1000
    ) {
      handleTokenRefresh();
    }
  }, []);
  const [reactivarNot, setReactivarNot] = useState(true);

  const refreshTokenNoti = async () => {
    localStorage.removeItem("tokenNoti");
    try {
      // Eliminar el token actual
      await deleteToken(messaging);
      setReactivarNot(false);

      setTimeout(() => {
        setReactivarNot(true);
      }, 70000);

      // Obtener un nuevo token
      const newToken = await getToken(messaging);
      if (newToken) {
        console.log("Nuevo token:", newToken);
        setIsReadyForNotification(false);
        localStorage.setItem("tokenNoti", newToken);
        mandarToken();
      } else {
        console.log("No se pudo obtener el nuevo token.");
      }
    } catch (error) {
      console.error("Error al eliminar el token:", error);
      console.log("Código de error:", error.code);
      console.log("Mensaje de error:", error.message);
      const userToken = getToken(messaging);

      messaging
        .unsubscribeFromTopic(userToken)
        .then((response) => {
          console.log(
            "Successfully unsubscribed from user notifications:",
            response
          );
        })
        .catch((error) => {
          console.log("Error unsubscribing from user notifications:", error);
        });

      getToken(messaging)
        .then((currentToken) => {
          if (currentToken) {
            console.log("Token nuevo:", currentToken);
          } else {
            console.log(
              "No se pudo obtener el token. Solicita permisos para mostrar notificaciones."
            );
          }
        })
        .catch((err) => {
          console.error("Error al obtener el token:", err);
        });
    }
  };

  const verificarNotificaciones = () => {
    if (localStorage.getItem("tokenNoti") !== null) {
      setIsReadyForNotification(false);
      if (!("Notification" in window)) {
        setIsReadyForNotification(false);
      }
    }
  };

  const llamadaList = async (e) => {
    let dnii = localStorage.getItem("userDataAcopio");
    let tokenList = localStorage.getItem("token");
    try {
      const res = await axios.post(
        SEND_TOKEN_NOTI,
        JSON.stringify({
          accion: "listadoNotificacionApp",
          dni: dnii,
          token: tokenList,
        })
      );
      if (res.status === 200) {
        let tablaDatos = res.data.message;
        // Calculamos el total de páginas en función del número de elementos y el total de elementos
        const totalPages = Math.ceil(
          tablaDatos.length / NUM_ELEMENTOS_POR_PAGINA
        );
        setTotalPages(totalPages);
        if (tablaDatos.length > 0) {
          let resp = tablaDatos.map((notificacion) => {
            let leido = false;
            // buscar en el almacenamiento local si la notificación ya se ha marcado como leída
            const key = `notificacion-${notificacion.fecha}`; // suponiendo que cada notificación tiene un id único
            const storedNotificacion = localStorage.getItem(key);
            if (storedNotificacion) {
              leido = JSON.parse(storedNotificacion).leido;
            }
            return {
              ...notificacion,
              leido: leido,
            };
          });
          setDatosUser(resp);
          resp.forEach((notificacion) => {
            const key = `notificacion-${notificacion.fecha}`; // suponiendo que cada notificación tiene un id único
            localStorage.setItem(
              key,
              JSON.stringify({ leido: notificacion.leido })
            );
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const marcarComoLeido = (fecha) => {
    const nuevosDatos = datosUser.map((datos) => {
      if (datos.fecha === fecha) {
        const key = `notificacion-${fecha}`;
        localStorage.setItem(key, JSON.stringify({ leido: true }));
        return {
          ...datos,
          leido: true,
        };
      }
      return datos;
    });
    setDatosUser(nuevosDatos);
  };

  const filteredData = datosUser.slice(
    (currentPage - 1) * NUM_ELEMENTOS_POR_PAGINA,
    currentPage * NUM_ELEMENTOS_POR_PAGINA
  );

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const totalPages = Math.ceil(datosUser.length / NUM_ELEMENTOS_POR_PAGINA);
    setTotalPages(totalPages);
    const newStartIndex = (currentPage - 1) * NUM_ELEMENTOS_POR_PAGINA;
    const newEndIndex = Math.min(
      newStartIndex + NUM_ELEMENTOS_POR_PAGINA,
      datosUser.length
    );
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }, [datosUser, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    onMessage(messaging, (message) => {
      console.log("tu mensaje:", message);
      toast.info(message.data.body, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
    // Comprobamos si el navegador soporta las notificaciones

    if (!localStorage.getItem("token")) {
      window.location.href = "./";
    }
    //tokennotificacion();
    if ("Notification" in window) {
      if (Notification.permission === "default") {
        mensajeDiario();
      }
      verificarNotificaciones();
      if (Notification.permission === "granted") {
        activarMensajes();
        setIsReadyForNotification(false);
      }
      if (Notification.permission === "denied") {
        setIsReadyForNotification(false);
      }
    } else if (!("Notification" in window)) {
      setIsReadyForNotification(false);
    }
    llamadaList();
    mandarToken();
  }, []);

  return (
    <div className="container-fluid">
      <ToastContainer />

      <>
        {" "}
        <div className="row justify-content-center contenedorTabla">
          <div className="col-12 contLogo">
            <img className="img_logoIni" src={Img} alt="logo" />
          </div>
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12 table-responsive contTabla">
            <div className="table-wrapper">
              <table className="table table-hover table-responsive" id="grilla">
                <thead>
                  <tr>
                    <th
                      className="contenDes text-left"
                      style={{ color: "#000000" }}
                    >
                      FECHA
                    </th>
                    <th
                      className="contMonto text-center"
                      style={{ color: "#000000" }}
                    >
                      ASUNTO
                    </th>
                  </tr>
                </thead>
                <tbody
                  style={{ backgroundColor: " #333333" }}
                  name="datosCompra"
                  id="datosCompra"
                >
                  {filteredData.map((datos) => {
                    return (
                      <tr
                        key={datos.fecha}
                        onClick={() => marcarComoLeido(datos.fecha)}
                      >
                        <td
                          className={
                            datos.leido
                              ? "leido text-center contFecha"
                              : "noLeido text-center contFecha"
                          }
                        >
                          <span>{datos.fecha}</span>
                        </td>
                        <td
                          className={
                            datos.leido
                              ? "leido text-left contTexto"
                              : "noLeido text-left contTexto"
                          }
                        >
                          <div>
                            <span>{datos.texto}</span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 text-center contPaginacion">
            <div>
              <div className="pagination">
                <button
                  className="btn btnSig btnChico btnRed"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FontAwesomeIcon
                    icon={faAnglesLeft}
                    className="colorIconoBtn"
                  />
                </button>
                <span className="txtMost">
                  Mostrando {startIndex + 1} - {endIndex} de {datosUser.length}{" "}
                </span>
                <button
                  className="btn btnSig btnChico btnRed"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <FontAwesomeIcon
                    icon={faAnglesRight}
                    className="colorIconoBtn"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="row">
        {isReadyForNotification && (
          <div className="col-12 contDown">
            <button className="botonesIni" onClick={notifyMe}>
              Activar Notificaciones
            </button>
          </div>
        )}
        {isReadyForInstall && (
          <div className="col-12 contDown">
            <button className="botonesIni" onClick={downloadApp}>
              Descargar
            </button>
          </div>
        )}
        <div className="col-12 contDown">
          <button className="botonesIni" onClick={Logout}>
            Cerrar Sesión
          </button>
        </div>
        {reactivarNot && (
          <div className="col-12 contDown">
            <button className="botonesReacti" onClick={refreshTokenNoti}>
              Reactivar Notificaciones
            </button>
          </div>
        )}
        <div className="col-12 contTxt">
          <span className="txtNot">v4.2</span>
        </div>
      </div>
    </div>
  );
};

export default Inicio;
