import React, { useEffect, useState,  useContext } from 'react'
import Img from '../assets/LogoZuppaHermanostrazo.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/style.css";
import "../css/login.css";
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss';
import { ApiUrlUsuarios } from '../Apis/ApiUsuarios';
import AuthContext from "../context/AuthProvider";
import axios from 'axios';



const LOGIN_URL = ApiUrlUsuarios;



const Login = () => {


    const { setAuth } = useContext(AuthContext);


    const [dni, setDni] = useState(null);
    const [password, setPassword] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(LOGIN_URL, JSON.stringify({
                accion: "login",
                dni: dni,
                password: password
            }), {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }).then(res => {
                if (res.data.status === 'true') {
                    console.log(res.data)
                    localStorage.setItem("userDataAcopio", dni)
                    localStorage.setItem("token", res.data.token)                    
                    localStorage.setItem("nombre", res.data.message.nombre)
                    localStorage.setItem("nom_ape", res.data.message.nom_ape)
                    localStorage.setItem("apellido", res.data.message.apellido)
                    window.location.href = "/Inicio";
                } else if (res.data.status === 'false') {
                    if (res.data.id_message === "403") {
                        Swal.fire({
                            title: 'Atención! ',
                            titleColor: '#E85217',
                            text: `${res.data.message}`,
                            showCancelButton: true,
                            confirmButtonColor: '#E85217',
                            cancelButtonColor: '#666666',
                            confirmButtonText: 'Si!',
                            cancelButtonText: 'Cancelar'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = './Registro';
                            }
                        })
                    } else {
                        Swal.fire({
                            title: 'Atención! ',
                            titleColor: '#E85217',
                            text: `${res.data.message}`,
                            confirmButtonColor: '#E85217'
                        });
                    }
                }
            })
            const token = res && res.data && res.data.token;
            setAuth({ dni, token })
            setDni('');
            setPassword('');

        } catch (err) {
            if (!err?.res) {
                console.log("No responde el server")
            }
        }
    }

 useEffect(() => {
    if (localStorage.getItem('token') !== null) {
        window.location.href = "./Inicio";
    }
}, [])


    return (
        <main>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-xl-4 col-lg-5 col-md-8 col-sm-10 col-11 boxLogin'>
                        <div className='row'>
                            <div className='col-12'>
                                <form className='form' onSubmit={handleSubmit}>
                                    <img className='img_logo' src={Img} alt="logo"/>
                                    <h2 className='form__title'>Bienvenido!</h2>


                                    <div className='form__container'>

                                        <div className='form__group'>
                                            <input
                                                type="text"
                                                className="form__input"
                                                placeholder=" "
                                                value={dni}
                                                id="dni"
                                                name='dni'
                                                onChange={(e) => setDni(e.target.value)} />

                                            <label htmlFor="dni" className='form__label'>DNI</label>
                                            <span className='form__line'></span>
                                        </div>
                                        <div className='form__groupDos'>
                                            <input
                                                type="password"
                                                className="form__input"
                                                placeholder=" "
                                                value={password}
                                                id="password"
                                                name='password'
                                                onChange={(e) => setPassword(e.target.value)} />

                                            <label htmlFor="password" className='form__label'>Contraseña</label>
                                            <span className='form__line'></span>
                                        </div>
                                        <div className='row contenedorBtnsIni'>
                                            <div className='col-12 contBotInicio'>
                                                <button className='btn btnLogin' >Iniciar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    );
}
export default Login;