import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Inicio from '../pages/Inicio';
import Login from '../pages/Login';
import { getAuth, signInAnonymously } from "firebase/auth";



function App() {
  const loguearse = () => {
    signInAnonymously(getAuth()).then(usuario => console.log(usuario));
  }

  React.useEffect(() => {
    loguearse();
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>
        <Route exact path="/inicio" element={<Inicio />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
